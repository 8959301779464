var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agency-user-roles animatedBox"},[_c('div',{staticClass:"container fluid"},[_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.agencyRolesTableColumns,"rows":_vm.agencyRolesTableData,"totalRows":_vm.agencyRolesTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[(props.row.isEditable && _vm.canEdit)?_c('button',{staticClass:"btn mx-1",on:{"click":function($event){return _vm.openEditRoleModal(props.row)}}},[_c('i',{staticClass:"fas fa-pen"})]):_vm._e()]):_vm._e(),(props.column.field == 'description')?_c('span',[(props.row.description)?_c('p',[_vm._v(_vm._s(props.row.description))]):_c('p',[_vm._v("No description")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[(_vm.canAdd)?_c('button',{staticClass:"btn main mr-2",on:{"click":_vm.openAddNewRoleModal}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e()])])],1)]),_c('modal',{model:{value:(_vm.isEditRoleModalShown),callback:function ($$v) {_vm.isEditRoleModalShown=$$v},expression:"isEditRoleModalShown"}},[_c('edit-role',{attrs:{"roleData":_vm.currentEditRole},on:{"update":_vm.updateAgencyUserRole,"close":function($event){_vm.isEditRoleModalShown = false}}})],1),_c('modal',{model:{value:(_vm.isAddRoleModalShown),callback:function ($$v) {_vm.isAddRoleModalShown=$$v},expression:"isAddRoleModalShown"}},[_c('add-role',{on:{"create":_vm.createAgencyUserRole,"close":function($event){_vm.isAddRoleModalShown = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }